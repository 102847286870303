.stage {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 100%;
  gap: 2px;
  overflow: hidden;
  height: 100%;
}

.stageCenter {
  grid-column: 1 / 14;
}

.stageSidebar {
  grid-column: 14 / 17;
  display: grid;
  gap: 2px;
  grid-template-rows: repeat(4, 1fr);
  overflow-y: hidden;
}

.stage div, .stage video {
  border-radius: 0;
}
