.stage {
  display: grid;
  gap: 2px;
  overflow: hidden;
  height: 100%;
}

.stage div, .stage video {
  border-radius: 0;
}

.grid1x1 {
  grid-template-columns: auto;
  grid-template-rows: auto;
}

.grid2x1 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
}

.grid2x2 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.grid3x3 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}